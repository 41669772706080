import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { css } from '@emotion/core'
import axios from 'axios'

import { SearchModalNormal } from './searchModalNormal'
import { SearchModalSpecial } from './searchModalSpecial'

axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'

const SearchModal = ({
  hide,
  addFiltering,
  setThinning,
  specialSearch,
  setSearchFlag,
  setSpecialSearchID,
  specialSearchID,
  searchParams,
  filtering,
  markerDrop
}: any) => {
  // 顧客詳細情報表示用モーダル

  const HideSearchModal = () => {
    hide()
  }

  // 検索or特殊検索
  const [searchType, setSearchType] = useState(1)
  const changeType = (num: number) => {
    setSearchType(num)
  }

  return (
    // 顧客詳細情報表示用モーダル
    ReactDOM.createPortal(
      <>
        <div className="modal-overlay" />
        <button css={CloseBtn} type="button" onClick={() => HideSearchModal()}>
          <img
            src={`${window.location.origin}/images/close.svg`}
            width="30"
            height="30"
            alt=""
          />
        </button>
        <div css={SearchModalWrapper} tabIndex={-1} role="dialog">
          <div className="modal">
            <div css={ModalContent}>
              <p css={ModalContentTtl}>検索</p>
              <div css={radioContainer}>
                <label css={searchTypeRadio}>
                  <input
                    type="radio"
                    name="searchType"
                    value={searchType}
                    checked={searchType === 1 && true}
                    onChange={() => changeType(1)}
                  />
                  通常検索
                </label>
                <label css={searchTypeRadio}>
                  <input
                    type="radio"
                    name="searchType"
                    value={searchType}
                    checked={searchType === 2 && true}
                    onChange={() => changeType(2)}
                  />
                  特殊検索
                </label>
              </div>

              {searchType === 1 ? (
                <SearchModalNormal
                  hide={hide}
                  addFiltering={addFiltering}
                  setThinning={setThinning}
                  setSearchFlag={setSearchFlag}
                  filtering={filtering}
                />
              ) : (
                <SearchModalSpecial
                  hide={hide}
                  addFiltering={addFiltering}
                  setThinning={setThinning}
                  specialSearch={specialSearch}
                  setSearchFlag={setSearchFlag}
                  specialSearchID={specialSearchID}
                  searchParams={searchParams}
                  setSpecialSearchID={setSpecialSearchID}
                  markerDrop={markerDrop}
                />
              )}
            </div>
          </div>
        </div>
      </>,
      document.body
    )
  )
}

// export default hot(Modal)

export default SearchModal

const SearchModalWrapper = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 80%;
  height: 70%;
  overflow: scroll;
  overflow-wrap: break-word;
  background: none rgb(255, 255, 255);
  border: 0px;
  // user-select: none;
  outline: none;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  @media (max-width: 768px) {
    width: 90%;
    height: 80%;
  }
`

const ModalContent = css`
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  max-width: 800px;
  margin: auto;
  @media (max-width: 768px) {
    padding: 40px 20px 40px 40px;
  }
  @media (max-width: 450px) {
    padding: 20px;
  }
`

const ModalContentTtl = css`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #0e2851;
`

const CloseBtn = css`
  position: absolute;
  right: 0;
  border: 0px;
  margin: 20px;
  padding: 0px;
  cursor: pointer;
  // user-select: none;
  height: 30px;
  width: 30px;
  outline: none;
  background: none;
  top: 15%;
  left: 80%;
  z-index: 2;
  @media (max-width: 450px) {
    top: 10%;
  }
`

const radioContainer = css`
  display: flex;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 30px;
  padding-bottom: 20px;
`
const searchTypeRadio = css`
  margin-right: 15px;
  cursor: pointer;
`
