import React, { useState, useCallback, memo } from 'react'
import { css } from '@emotion/core'
import axios from 'axios'
import { ModalButtons } from './ModalButtons'
import { InputCheckBox } from './InputCheckBox'

axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'

export const SearchModalSpecial = memo(
  ({
    hide,
    specialSearch,
    setSearchFlag,
    setSpecialSearchID,
    specialSearchID,
    searchParams,
    markerDrop
  }: any) => {
    // 特殊検索用
    const [cashlessVal, setCashlessVal] = useState(specialSearchID)

    const sendSearchInput = () => {
      // id複数の時の渡し方も確認必要
      const specialSearchID = cashlessVal ? 1 : null
      const data = searchParams

      setSpecialSearchID(specialSearchID)
      if (specialSearchID) {
        setSearchFlag(2)
        specialSearch({ specialSearchID, data })
      } else {
        setSearchFlag(1)
        markerDrop(data)
      }
      hide()
    }

    const clearSearchInput = () => {
      // 何チェックしてない時の挙動どうする？
      setCashlessVal(false)
      setSpecialSearchID(0)
    }

    const handleChange = useCallback(() => {
      setCashlessVal(!cashlessVal)
    }, [cashlessVal])

    return (
      <>
        <div css={specialSearchContainer}>
          <InputCheckBox
            onChange={handleChange}
            val={cashlessVal}
            text={'キャッシュレスの初回決済が必要な店舗'}
          />
        </div>

        <ModalButtons
          clearSearchInput={clearSearchInput}
          sendSearchInput={sendSearchInput}
        />
      </>
    )
  }
)

const specialSearchContainer = css`
  // padding: 0 20px;
  display: flex;
  flex-direction: column;
`
