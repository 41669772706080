import React, { Component, ReactElement } from 'react'
import { Motion, spring } from 'react-motion'
import { Pin } from '../atoms/Pin'

interface DelayProps {
  initial: any
  key: any
  value: any
  period: any
  children(data: Array<{}>): ReactElement
}

// {initial: -1000, value: 0, period: 5, children: ƒ}

class Delay extends Component<DelayProps> {
  static defaultProps = {
    period: 0
  }
  state = {
    value: this.props.initial
  }
  timeout: any

  componentDidMount = () => {
    let { value, period } = this.props
    this.timeout = setTimeout(() => this.setState({ value }), period)
  }

  // componentDidUpdate(prevProps:any, prevState:any) {
  //     // console.log(prevProps, prevState)
  //     // this.refresh(prevProps);
  //     let {value, period} = prevProps
  //     this.timeout = setTimeout(() => this.setState({
  //         value
  //     }), period);
  //   }
  componentWillUnmount = () => {
    clearTimeout(this.timeout)
  }
  render = () => {
    // function-as-children
    return this.props.children(this.state.value)
  }
}

const Marker = (props: any) => {
  const { pin, onSelect, useAnimation } = props
  const randnum = 1 + Math.floor(Math.random() * 300)

  let specialSearch: boolean
  // 特殊検索にしか存在しないのプロパティがあればtrue
  if (pin.top_priority_contact_detail) {
    specialSearch = true
  } else {
    specialSearch = false
  }

  return (
    <div
      key={pin.id}
      onClick={() => onSelect(pin.lat, pin.lon, specialSearch ? pin : null)}
    >
      {(() => {
        if (useAnimation === true) {
          return (
            <Delay key={pin.id} initial={-1000} value={0} period={randnum}>
              {(delayed: any) => (
                <Motion
                  style={{
                    y: spring(delayed, { stiffness: 210, damping: 20 }),
                    opacity: spring(0)
                  }}
                >
                  {(val: any) => (
                    <div
                      style={{
                        transform: `translate3d( 0, ${val.y}px, 0)`,
                        opacity: 1
                      }}
                    >
                      <Pin pin={pin} />
                    </div>
                  )}
                </Motion>
              )}
            </Delay>
          )
        } else {
          return <Pin pin={pin} />
        }
      })()}
    </div>
  )
}

export default Marker
