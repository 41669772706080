import React, { useState } from 'react'
import { css } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { base_url } from '../../../utils/constants'

import Spinner from '../../components/atoms/Spinner'

const Login = () => {
  const history = useHistory()
  axios.defaults.withCredentials = true

  const [userName, setUserName] = useState('')
  const [passWord, setPassWord] = useState('')
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleChanges = (e: any, key: string) => {
    switch (key) {
      case 'user':
        setUserName(e.target.value)
        break
      case 'pass':
        setPassWord(e.target.value)
        break
    }
  }

  const loginAuth = () => {
    const url = `${base_url}/api/login`
    setIsLoading(true)
    axios
      .post(url, {
        username: userName,
        password: passWord
      })
      .then(res => {
        console.log(res)
        history.push('/map')
      })
      .catch(err => {
        setIsError(true)
        setIsLoading(false)
      })
  }

  return (
    <div css={FlexColumnCenter}>
      <p css={Title}>Smart Sell Map</p>
      <div css={InputArea}>
        <input
          css={InputBox}
          type="text"
          placeholder="user name"
          onChange={e => handleChanges(e, 'user')}
          value={userName}
        />
        <input
          css={InputBox}
          type="password"
          placeholder="password"
          onChange={e => handleChanges(e, 'pass')}
          value={passWord}
        />
      </div>
      {isError === true ? (
        <p css={ErrorMsg}>入力に間違いがあります。もう一度入力してください</p>
      ) : null}
      <button css={LoginBtn} onClick={loginAuth}>
        Login
        {isLoading === true ? (
          <div>
            <Spinner size={'small'} />
          </div>
        ) : (
          ''
        )}
      </button>
    </div>
  )
}

const FlexColumnCenter = css(`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 94%;
    background: #feffff;
    background: -moz-linear-gradient(top, #feffff 0%, #ddf1f9 35%, #a0d8ef 100%);
    background: -webkit-linear-gradient(top, #feffff 0%,#ddf1f9 35%,#a0d8ef 100%);
    background: linear-gradient(to bottom, #feffff 0%,#ddf1f9 35%,#a0d8ef 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#feffff', endColorstr='#a0d8ef',GradientType=0 );
`)

const Title = css(`
    font-family: sans-serif;
    font-size: 1.4rem;
    font-weight:bold;
    color: #0e2851;
`)

const InputArea = css(`
    display: flex;
    flex-direction: column;
    margin-top:25px;
`)

const InputBox = css(`
    font-family: inherit;
    width: 280px;
    border: 1px solid #A6A6A6;
    padding: 10px;
    font-size: 16px;
    transform: scale(0.9);
    border-radius: 3px;
    margin: 5px;
    -webkit-appearance: none;
`)
const LoginBtn = css(`
    font-family: inherit;
    cursor: pointer;
    font-size: 16px;
    width: 220px;
    padding: 8px 30px;
    border-radius: 20px;
    border: none;
    background: #0e2851;
    color: #ffffff;
    outline: none;
    margin-top:25px;
    display: flex;
    justify-content: center;
    align-items: center;
`)

const ErrorMsg = css(`
    // font-size: 14px;
    font-size: 16px;
    transform: scale(0.9);
    font-weight: bold;
    color: #ff4d4d;
    margin-top: 10px;
`)

export default Login
