import React from 'react'
import axios from 'axios'
import SearchModal from '../../../pages/Map/searchModal'
import InfoModal from '../../../pages/Map/infoModal'

axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'

const Modal = ({
  isShowing,
  selectedInfo,
  hide,
  addFiltering,
  updateModal,
  setThinning,
  specialSearch,
  setSearchFlag,
  setSpecialSearchID,
  specialSearchID,
  searchParams,
  filtering,
  markerDrop
}: any) => {
  // 顧客詳細情報表示用モーダル

  return (
    // 顧客詳細情報表示用モーダル
    selectedInfo ? (
      isShowing ? (
        <InfoModal
          hide={hide}
          selectedInfo={selectedInfo}
          addFiltering={addFiltering}
          updateModal={updateModal}
          setThinning={setThinning}
        />
      ) : null
    ) : // サーチ画面用モーダル
    isShowing ? (
      <SearchModal
        hide={hide}
        selectedInfo={selectedInfo}
        addFiltering={addFiltering}
        updateModal={updateModal}
        setThinning={setThinning}
        specialSearch={specialSearch}
        setSearchFlag={setSearchFlag}
        specialSearchID={specialSearchID}
        searchParams={searchParams}
        setSpecialSearchID={setSpecialSearchID}
        filtering={filtering}
        markerDrop={markerDrop}
      />
    ) : null
  )
}

// export default hot(Modal)

export default Modal
