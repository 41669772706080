import React, { memo } from 'react'
import { css } from '@emotion/core'

export const ModalButtons = memo(
  ({ clearSearchInput, sendSearchInput }: any) => {
    return (
      <div css={ModalContentBtnArea}>
        <button
          css={ModalContentClearBtn}
          type="button"
          className="modal-close-button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={clearSearchInput}
        >
          <span aria-hidden="true">Clear</span>
        </button>
        <button css={ModalContentBtn} onClick={sendSearchInput}>
          Search
        </button>
      </div>
    )
  }
)

const ModalContentBtnArea = css`
  width: 80%;
  max-width: 600px;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column-reverse;
    margin-top: 40px;
  }
`
const ModalContentBtn = css`
  font-family: inherit;
  cursor: pointer;
  font-size: 16px;
  width: 220px;
  padding: 8px 30px;
  border-radius: 20px;
  border: none;
  background: #0e2851;
  color: #ffffff;
  outline: none;
  margin: auto;
  @media (max-width: 768px) {
    // font-size: 14px;
    width: 180px;
    margin-bottom: 10px;
  }
`

const ModalContentClearBtn = css`
  font-family: inherit;
  cursor: pointer;
  font-size: 16px;
  width: 220px;
  padding: 8px 30px;
  border-radius: 20px;
  border: none;
  background: #cccccc;
  color: #ffffff;
  outline: none;
  margin: auto;
  @media (max-width: 768px) {
    // font-size: 14px;
    width: 180px;
  }
`
