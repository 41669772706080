import React from "react";
import { css } from '@emotion/core';


const Spinner = (props:any) =>{
    const loaderSize = props.size
    if (loaderSize === "small"){
        return(
            <div>
                <div css={LoaderSmall}/>
            </div>
        )
    }
    else{
        return(
            <div>
                <div css={Loader}/>
            </div>
        )
    }
}

export default Spinner


const Loader = css`
    position: absolute;
    width: 50px;
    height: 50px;
    border: 10px solid #dddddd;
    border-top-color: #aaaaaa;
    border-radius: 50%;
    animation: 1s spin infinite linear;
    @keyframes spin {
        from{
          transform: rotateZ(0deg);         /* アニメーション開始状態 */
        }
        to{
          transform: rotateZ(360deg);       /* アニメーション終了状態 */
        }
    }
`

const LoaderSmall = css`
    margin-left:10px;
    width: 7px;
    height: 7px;
    border: 3px solid #dddddd;
    border-top-color: #aaaaaa;
    border-radius: 50%;
    animation: 1s spin infinite linear;
    @keyframes spin {
        from{
          transform: rotateZ(0deg);         /* アニメーション開始状態 */
        }
        to{
          transform: rotateZ(360deg);       /* アニメーション終了状態 */
        }
    }
`