import React, { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import GoogleMapReact from 'google-map-react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

// components
import useModal from '../../components/modules/Modal/useModal'
import Modal from '../../components/modules/Modal/modal'
import Spinner from '../../components/atoms/Spinner'
import Marker from '../../components/modules/Marker'
import { InfoWindow } from './InfoWindow'

// utils
import { base_url } from '../../../utils/constants'
import { generateState } from '../../../utils/generateState'
import { fixCenterLat } from '../../../utils/fixCenterLat'

axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'

const APIKEY = process.env.REACT_APP_API_KEY
  ? process.env.REACT_APP_API_KEY
  : ''

const Map = () => {
  // ズーム値
  const [zoom, setZoom] = useState(15)

  // 初期表示画面
  const [currentPosition, setCurrentPosition] = useState<any>()
  const [center, setCenter] = useState(currentPosition)

  // 現在地表示/非表示
  const [showCurrentPosition, setShowCurrentPosition] = useState(true)
  const [geoLocation, setGeoLocation] = useState(false)

  // ピンの情報
  const [pinInfo, setpinInfo] = useState<any>()

  // 検索条件
  const [filtering, setFilterling] = useState({
    name: '',
    address: '',
    number: '',
    industries: generateState(15),
    services: generateState(18),
    scenario: generateState(241)
    // ignore_chain:"",
  })

  // クリックされた緯度経度
  const [selectedLat, setSelectedLat] = useState<any>()
  const [selectedLng, setSelectedLng] = useState<any>()
  // 緯度経度取得後API処理したデータを保持
  const [detailInfo, setDetailInfo] = useState<any>()
  // 選択したデータを保持
  const [selectedInfo, setselectedInfo] = useState('')
  // 間引き設定
  // 指定なし 全件数
  // 1       50件
  // 2       間引き（デフォ）
  const [thinning, setThinning] = useState('2')
  // モーダル表示/非表示
  const { isShowing, toggle } = useModal()

  // zoom制御のオプション
  const [scrollwheel, setscrollwheel] = useState(true)

  // オプション指定
  const createMapOptions = () => {
    return {
      fullscreenControl: false,
      scrollwheel: { scrollwheel }.scrollwheel,

      featureType: 'all',
      elementType: 'geometry',
      gestureHandling: 'auto',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    }
  }

  const history = useHistory()

  // setState
  // 初期表示地点
  // 注意 http接続の場合geolocationが動作しないため、現在地はlocalでは確認不可
  const success = (data: any) => {
    const currentPosition = {
      lat: data.coords.latitude,
      lng: data.coords.longitude
    }
    setCurrentPosition(currentPosition)
    setCenter(currentPosition)
    setGeoLocation(true)
  }

  const error = (data: any) => {
    const currentPosition = {
      lat: 35.680959106959,
      lng: 139.76730676352
    }
    setCurrentPosition(currentPosition)
    setCenter(currentPosition)
    setShowCurrentPosition(false)
  }

  const protocol = window.location.protocol
  // 位置情報取得
  useEffect(() => {
    if (protocol === 'http:') {
      error('')
    } else {
      navigator.geolocation.getCurrentPosition(success, error)
    }
  }, [protocol])

  // Mapのなんらかのアクションでピン情報更新
  const [lastParam, setLastParam] = useState({})

  // 選択したカテゴリー・商材のTrueのみを返す
  const newHash = (hash: any) =>
    Object.keys(hash).map((key: any) => {
      if (hash[key] === true) {
        return key
      } else {
        return null
      }
    })

  // 検索メソッド

  const [searchFlag, setSearchFlag] = useState(1)
  const [specialSearchID, setSpecialSearchID] = useState(true)
  const [searchParams, setSearchParams] = useState()

  const searchStore = (data: any) => {
    const paramsData:any = {
      center: data.center,
      zoom: data.zoom,
      sw: `${data.bounds.sw.lat},${data.bounds.sw.lng}`,
      ne: `${data.bounds.ne.lat},${data.bounds.ne.lng}`
    }
    setSearchParams(paramsData)
  }

  // 初期useEffect制御に使用
  const isFirstRender = useRef(true)
  useEffect(() => {
    if (isFirstRender.current) {
      // 初回レンダー判定
      isFirstRender.current = false // もう初回レンダーじゃないよ代入
    } else {
      switch (searchFlag) {
        case 1:
          markerDrop(searchParams)
          break
        case 2:
          const data = searchParams
          specialSearch({ specialSearchID, data })
          break

        default:
          break
      }
    }
  }, [searchParams])

  const markerDrop = (data: any) => {
    const url = `${base_url}/api/spots`

    const categoryHash = newHash(filtering.industries)
      .filter(Boolean)
      .map(Number)
    const productHash = newHash(filtering.services)
      .filter(Boolean)
      .map(Number)
    const scenarioHash = newHash(filtering.scenario)
      .filter(Boolean)
      .map(Number)
    const params = {
      center: `${data.center.lat},${data.center.lng}`,
      zoom: data.zoom,
      sw: data.sw,
      ne: data.ne,
      keywords: filtering.name,
      address: filtering.address,
      tel: filtering.number,
      category_id: categoryHash,
      kakutoku_id: productHash,
      scenario_id: scenarioHash,
      // ignore_chain:"",
      pattern: thinning
    }

    setZoom(data.zoom)
    setCenter(data.center)
    setLastParam(params)

    axios
      .get(url, { params: params })
      .then(res => {
        console.log(res)
        setpinInfo(res.data.data)
      })
      .catch(error => {
        console.log(error.response)
        if (error.response.status === 401) {
          console.log(error.response)
          alert('ログインしてください')
          history.push('')
        }
      })
  }

  // 検索条件追加時のみ
  const markerDropAddFilter = () => {
    const url = `${base_url}/api/spots`

    const lastParamArray = Object.values(lastParam)
    const categoryHash = newHash(filtering.industries)
      .filter(Boolean)
      .map(Number)
    const productHash = newHash(filtering.services)
      .filter(Boolean)
      .map(Number)
    const scenarioHash = newHash(filtering.scenario)
      .filter(Boolean)
      .map(Number)

    const params: any = {
      center: lastParamArray[0],
      zoom: lastParamArray[1],
      sw: lastParamArray[2],
      ne: lastParamArray[3],
      keywords: filtering.name,
      address: filtering.address,
      tel: filtering.number,
      category_id: categoryHash,
      kakutoku_id: productHash,
      scenario_id: scenarioHash,
      ignore_chain: '',
      pattern: thinning
    }

    // console.log(params)
    axios
      .get(url, { params: params })
      .then(res => {
        setpinInfo(res.data.data)
        // setSelectedLat('')
      })
      .catch(err => {
        // console.log('移動のピン更新失敗' + err) // 失敗
      })
  }

  // 特殊検索
  const specialSearch = ({ specialSearchID, data }: any) => {
    const url = `${base_url}/api/spots/special-feature/${specialSearchID}`

    // 要変更ここの値を取得して変更
    // paramsをMOdalに渡す
    const params: any = {
      center: `${data.center.lat},${data.center.lng}`,
      sw: data.sw,
      ne: data.ne
    }
    setZoom(data.zoom)

    // console.log(params)
    axios
      .get(url, { params: params })
      .then(res => {
        setpinInfo(res.data.data)
        // setSelectedLat('')
      })
      .catch(err => {
        // console.log('移動のピン更新失敗' + err) // 失敗
      })
  }

  const fetchCustomerData = (lat: any, lng: any, scenarioHash: any) => {
    if (!lat || !lng) {
      return
    }
    const params = {
      lat: lat,
      lon: lng,
      scenario_id: scenarioHash
    }

    const url = `${base_url}/api/spots/show/all`
    axios
      .get(url, {
        params: params
      })
      .then(response => {
        setDetailInfo(response.data.data)
        // console.log(response.data.data)
      })
      .catch(error => {
        // console.log(error.response)
      })
  }
  // ピン選択時
  const onSelect = async (lat: number, lng: number, pin?: any) => {
    setDetailInfo('')

    const newPosition:any = {
      lat: fixCenterLat(lat, zoom),
      lng: lng
    }
    setCenter(newPosition)
    await setSelectedLng(lng)
    await setSelectedLat(lat)

    // 特別検索の場合

    if (pin) {
      const lat = pin.lat
      const lon = pin.lon
      const newArray = pinInfo.filter(
        (item: any) => item.lat === lat && item.lon === lon
      )

      setDetailInfo(newArray)
    } else {
      // 通常検索の場合
      fetchCustomerData(
        lat,
        lng,
        newHash(filtering.scenario)
          .filter(Boolean)
          .map(Number)
      )
    }
  }

  const showModal = (info: any) => {
    const url = `${base_url}/api/spots/${info.id}`

    axios
      .get(url)
      .then(response => {
        setselectedInfo(response.data.data)
        toggle()
      })
      .catch(error => {})
  }

  const updateModal = (info: any) => {
    const url = `${base_url}/api/spots/${info.id}`

    axios
      .get(url)
      .then(response => {
        setselectedInfo(response.data.data)
      })
      .catch(error => {
        // console.log(error.response)
      })
  }

  const showSearchModal = () => {
    setselectedInfo('')
    toggle()
  }

  const clearDetailInfo = () => {
    setscrollwheel(true)
    setDetailInfo('')
    setSelectedLat('')
  }

  // 現在地表示/非表示
  const switchShowing = (boolean: boolean) => {
    if (boolean === true) {
      setShowCurrentPosition(false)
    } else {
      setShowCurrentPosition(true)
    }
  }

  const addFiltering = (filter: any) => {
    // console.log(filter)
    setFilterling(filter)
  }

  // 初期useEffect制御に使用
  const isFirstFiltering = useRef(true)
  useEffect(() => {
    // 『filtering or thinning』 が更新された場合『と』初回レンダー時に動くeffect
    if (isFirstFiltering.current) {
      // 初回レンダー判定
      isFirstFiltering.current = false // もう初回レンダーじゃないよ代入
    } else {
      markerDropAddFilter()
    }
  }, [filtering, thinning])

  //  スマホでInfoWindow内をスクロールさせる
  const isFirstRenderWindow = useRef(true)
  useEffect(() => {
    if (isFirstRenderWindow.current) {
      // 初回レンダー判定
      isFirstRenderWindow.current = false // もう初回レンダーじゃないよ代入
    } else {
      const scrollWindow = document.querySelector('.scrollWindow')
      if (!scrollWindow) {
        return
      }
      //   console.log(scrollWindow)
      scrollWindow.addEventListener('touchstart', (e: any) => {
        e.stopPropagation()
      })
      return () => {
        scrollWindow.removeEventListener('touchstart', (e: any) => {
          e.stopPropagation()
        })
        // console.log(scrollWindow)
      }
    }
  }, [detailInfo])

  return (
    <div style={{ height: '94%', width: '100%' }}>
      {currentPosition ? (
        <GoogleMapReact
          // APIKEYを設定
          bootstrapURLKeys={{ key: APIKEY }}
          // センター地点
          center={center}
          // ズーム値
          zoom={zoom}
          // イベント取得

          onChange={data => searchStore(data)}
          // scrollwhellをstateで管理すると型エラーのためboolean→anyに切り替えたので、戻す？
          options={createMapOptions()}
        >
          {/* 現在地 */}
          {currentPosition && showCurrentPosition && (
            <CurrentMarker
              lat={currentPosition.lat}
              lng={currentPosition.lng}
              draggable={true}
            />
          )}
          <Modal
            isShowing={isShowing}
            hide={toggle}
            selectedInfo={selectedInfo}
            addFiltering={addFiltering}
            updateModal={updateModal}
            setThinning={setThinning}
            specialSearch={specialSearch}
            setSearchFlag={setSearchFlag}
            specialSearchID={specialSearchID}
            searchParams={searchParams}
            setSpecialSearchID={setSpecialSearchID}
            filtering={filtering}
            markerDrop={markerDrop}
          />
          {/* 各種ピン */}
          {pinInfo
            ? (() => {
                // 全権取得かつ200件以上取得した場合の場合アニメーションなし
                if (thinning === '' && pinInfo.length > 200) {
                  return pinInfo.map((pin: any, i: any) => {
                    return (
                      <Marker
                        key={pin.id}
                        lat={pin.lat}
                        lng={pin.lon}
                        pin={pin}
                        i={i}
                        onSelect={onSelect}
                        useAnimation={false}
                      />
                    )
                  })
                } else {
                  return pinInfo.map((pin: any, i: any) => {
                    return (
                      <Marker
                        key={pin.id}
                        lat={pin.lat}
                        lng={pin.lon}
                        pin={pin}
                        i={i}
                        onSelect={onSelect}
                        useAnimation={true}
                      />
                    )
                  })
                }
              })()
            : null}

          {/* 選択した時 */}
          {selectedLat && (
            <InfoWindowBox
              key={selectedLat}
              className="scrollWindow"
              lat={selectedLat}
              lng={selectedLng}
              onMouseEnter={() => setscrollwheel(false)}
              onMouseLeave={() => setscrollwheel(true)}
            >
              <CloseBtn type="button" onClick={() => clearDetailInfo()}>
                <img
                  src={`${window.location.origin}/images/close.svg`}
                  width="30"
                  height="30"
                  alt=""
                />
              </CloseBtn>
              {detailInfo ? (
                <InfoWindow detailInfo={detailInfo} showModal={showModal} />
              ) : (
                <InfoWindowSpinnerWrapper>
                  <Spinner />
                </InfoWindowSpinnerWrapper>
              )}
            </InfoWindowBox>
          )}
        </GoogleMapReact>
      ) : (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {currentPosition ? (
        <div>
          {showCurrentPosition ? (
            <GoBackBtn onClick={() => setCenter(currentPosition)}>
              <img
                src={`${window.location.origin}/images/pointer.svg`}
                width="25"
                height="25"
                alt=""
              />
            </GoBackBtn>
          ) : null}
          {geoLocation ? (
            <ShowCurrentPositionBtn
              onClick={() => switchShowing(showCurrentPosition)}
            >
              <img
                src={`${window.location.origin}/images/current_pin.svg`}
                width="25"
                height="25"
                alt=""
              />
            </ShowCurrentPositionBtn>
          ) : null}
          <ShowSearchModalBtn onClick={() => showSearchModal()}>
            <img
              src={`${window.location.origin}/images/search.svg`}
              width="25"
              height="25"
              alt=""
            />
          </ShowSearchModalBtn>
          {searchFlag === 1 && (
            <ThinningSelectBox
              value={thinning}
              // onChange={ (e:any) => setThinning(e.value)}
              onChange={(e: any) => setThinning(e.target.value)}
            >
              <option value="2">間引き</option>
              <option value="1">50件</option>
              <option value="">全表示</option>
            </ThinningSelectBox>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default Map

const BoxStyle = styled.div`
  background: none rgb(255, 255, 255);
  border: 0px;
  margin: 10px;
  padding: 0px;
  cursor: pointer;
  // user-select: none;
  border-radius: 2px;
  // box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  outline: none;
`

const CurrentMarker = styled.div<{ lat: number; lng: number }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #4987f2;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  margin: auto;
  border: solid 2px #fff;
  box-shadow: 0 0 0 1px #6196f2;
`

const InfoWindowBox = styled(BoxStyle)<{ lat: number; lng: number }>`
  cursor: default;
  margin: 0;
  width: 310px;
  height: 215px;
  position: absolute;
  z-index: 99999999;
  top: -250px;
  left: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: -24px;
    display: block;
    width: 0;
    height: 0;
    margin: 0 auto;
    border-style: solid;
    border-width: 25px 25px 0 25px;
    border-color: #ffffff transparent transparent transparent;
  }
  @media (min-width: 450px) {
    width: 550px;
    height: 350px;
    top: -390px;
  }
`

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 48%;
  transform: translateY(-50%) translateX(-48%);
  -webkit-transform: translateY(-50%) translateX(-48%);
  @media (max-width: 450px) {
    left: 40%;
    transform: translateY(-50%) translateX(-40%);
    -webkit-transform: translateY(-50%) translateX(-40%);
  }
`

const InfoWindowSpinnerWrapper = styled.div`
  position: absolute;
  top: 35%;
  left: 39%;
  transform: translateY(-35%) translateX(-39%);
  -webkit-transform: translateY(-35%) translateX(-39%);
  @media (min-width: 450px) {
    top: 40%;
    left: 44%;
    transform: translateY(-40%) translateX(-44%);
    -webkit-transform: translateY(-40%) translateX(-44%);
  }
`

const GoBackBtn = styled(BoxStyle)`
    position: absolute;
    bottom : 155px;
    right 0px;
    height: 40px;
    width: 40px;
    overflow: hidden;
    display:flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        bottom : 105px;
    }
`

const ShowCurrentPositionBtn = styled(BoxStyle)`
  position: absolute;
  bottom: 105px;
  right: 0;
  height: 40px;
  width: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
        right: unset;
        bottom : 75px;
        left 0px;
    };
`

const ShowSearchModalBtn = styled(BoxStyle)`
  position: absolute;
  top: 90px;
  left: 0px;
  height: 40px;
  width: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 15px;
  @media (max-width: 768px) {
    top: unset;
    bottom: 25px;
    left: 0px;
    margin: 10px;
  }
`
const ThinningSelectBox = styled.select`
    font-family: sans-serif;
    position: absolute;
    top : 55px;
    left 0px;
    background: none rgb(255, 255, 255);
    border: 0px;
    // margin: 10px;
    padding: 0px;
    cursor: pointer;
    // user-select: none;
    border-radius: 2px;
    height: 40px;
    width: 150px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    font-size: 16px;
    transform: scale(0.8);
    @media (max-width: px) {
        top : 50px;
        height: 30px;
        width: 100px;
        padding: 0 5px;
    }
`

const CloseBtn = styled.button`
  border: 0px;
  margin: 10px 10px;
  position: absolute;
  z-index: 2;
  right: 0;
  border-radius: 50%;
  padding: 0px;
  cursor: pointer;
  // user-select: none;
  height: 30px;
  width: 30px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background: none rgb(255, 255, 255);
`
