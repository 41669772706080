import React, { memo } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const ModalCheckbox = memo(({ item, array, onChange }: any) => {
  return (
    <label css={checkBoxLabel} key={item.id}>
      <input
        type="checkbox"
        id={item.id}
        name={item.title}
        checked={array[item.id]}
        onChange={onChange}
        css={ModalContentCheckBox}
      />
      <StyledCheckbox checked={array[item.id]}>
        <CheckIcon
          src={`${window.location.origin}/images/check.svg`}
          checked={array[item.id]}
        />
      </StyledCheckbox>
      {item.title}
    </label>
  )
})

const ModalContentCheckBox = css({
  display: 'none'
})

const checkBoxLabel = css({
  width: '100%',
  marginTop: '10px',
  display: 'inline-block',
  verticalAlign: 'top',
  cursor: 'pointer',
  fontSize: '13px',
  '@media(min-width: 768px)': {
    width: '30%',
    marginRight: '3.3%'
  }
})
const StyledCheckbox = styled.div<{ checked: any }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: ${props => (props.checked ? '#0e2851' : '#eee')};
  border-radius: 3px;
  transition: all 150ms;
  margin-right: 10px;
`
const CheckIcon = styled.img<{ src: any; checked: any }>`
  width: 10px;
  height: 10px;
  visibility: ${props => (props.checked ? 'visible' : 'hidden')};
`
