import React, { memo } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const InputCheckBox = memo(({ onChange, val, text }: any) => {
  return (
    <label css={specialSearchLabel} onChange={onChange}>
      <input css={specialSearchInput} type="checkbox" name="" id="" />
      <StyledCheckbox checked={val}>
        <CheckIcon
          src={`${window.location.origin}/images/check.svg`}
          checked={val}
        />
      </StyledCheckbox>
      {text}
    </label>
  )
})

const StyledCheckbox = styled.div<{ checked: any }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: ${props => (props.checked ? '#0e2851' : '#eee')};
  border-radius: 3px;
  transition: all 150ms;
  margin-right: 10px;
`
const CheckIcon = styled.img<{ src: any; checked: any }>`
  width: 10px;
  height: 10px;
  visibility: ${props => (props.checked ? 'visible' : 'hidden')};
`

const specialSearchLabel = css`
  width: 100%;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  align-items: center;
`

const specialSearchInput = css`
  -webkit-appearance: none;
  display: none;
`
