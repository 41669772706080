import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Accordion } from '../../components/modules/Accordion'
import { css } from '@emotion/core'
import axios from 'axios'
import { base_url } from '../../../utils/constants'
// import console = require('console');

axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'

const ScrollBottom = () => {
  const target = document.getElementById('detailModal')
  if (target) {
    target.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }
}

const InfoModal = ({ hide, selectedInfo, updateModal }: any) => {
  // 顧客詳細情報表示用モーダル

  // Postする内容を保持
  const [PostContent, setPostContent] = useState('')
  const [PostRecord, setPostRecord] = useState('')
  const [isOpen, setOpen] = useState(false)

  const [isError, setIsError] = useState(false)

  const changeOpen = () => {
    setOpen(!isOpen)
  }

  useEffect(() => {
    const target = document.getElementById('transitionTarget')
    if (target) {
      target.addEventListener('transitionend', () => {
        ScrollBottom()
      })
      return () => {
        target.removeEventListener('transitionend', () => {
          ScrollBottom()
        })
      }
    }
  }, [isOpen])

  const PostMemo = (selectedInfo: any) => {
    const id = selectedInfo.id
    const url = `${base_url}/api/spots/${id}/memo`
    axios
      .post(url, {
        id: id,
        code: Number(PostRecord),
        text: PostContent
      })
      .then(res => {
        // console.log('メモPost成功')
        setPostContent('')
        setPostRecord('')
        updateModal(selectedInfo)
        changeOpen()
        setIsError(false)
      })
      .catch(err => {
        setIsError(true)
        // console.log(err) // 失敗
      })
  }

  const handleChange = (e: any, key: string) => {
    switch (key) {
      case 'number':
        setPostRecord(e.target.value)
        break
      case 'content':
        setPostContent(e.target.value)
        break
    }
  }

  const HideSearchModal = () => {
    hide()
    setOpen(false)
    setIsError(false)
  }
  // console.log(selectedInfo)

  return (
    // 顧客詳細情報表示用モーダル
    ReactDOM.createPortal(
      <React.Fragment>
        <div className="modal-overlay" />
        <button css={CloseBtn} type="button" onClick={() => HideSearchModal()}>
          <img
            src={`${window.location.origin}/images/close.svg`}
            width="30"
            height="30"
            alt=""
          />
        </button>
        <div css={ModalWrapper} tabIndex={-1} role="dialog">
          <div className="modal" id="detailModal">
            <div css={ModalContent}>
              <p css={ModalContentTtl}>詳細</p>
              <div css={ModalContentItem}>
                <p css={ModalContentItemHeading}>顧客名</p>
                {/* <button css={CopyBtn} onClick={()=>copyInfo("name")}>copy</button> */}
                <p id="copyTargetName">
                  {selectedInfo.title ? selectedInfo.title : '-'}
                </p>
              </div>
              <div css={ModalContentItem}>
                <p css={ModalContentItemHeading}>住所</p>
                <p>{selectedInfo.address ? selectedInfo.address : '-'}</p>
              </div>
              <div css={ModalContentItem}>
                <p css={ModalContentItemHeading}>獲得済み商材</p>
                <p>{selectedInfo.kakutoku ? selectedInfo.kakutoku : '-'}</p>
              </div>
              <div css={ModalContentItem}>
                <p css={ModalContentItemHeading}>電話番号</p>
                <p>
                  {selectedInfo.tel ? (
                    <a href={`tel:${selectedInfo.tel}`}>{selectedInfo.tel}</a>
                  ) : (
                    '-'
                  )}
                </p>
              </div>
              <div css={ModalContentItem}>
                <p css={ModalContentItemHeading}>代表者</p>
                <p>
                  {selectedInfo.representative
                    ? selectedInfo.representative
                    : '-'}
                </p>
              </div>
              <div css={ModalContentItem}>
                <p css={ModalContentItemHeading}>URL</p>
                <p>
                  {selectedInfo.url ? (
                    <a
                      href={selectedInfo.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedInfo.url}
                    </a>
                  ) : (
                    '-'
                  )}
                </p>
              </div>
              <div css={ModalContentItem}>
                <p css={ModalContentItemHeading}>シナリオ</p>
                <p>{selectedInfo.scenario ? selectedInfo.scenario : '-'}</p>
              </div>
              <div css={ModalContentItem}>
                <p css={ModalContentItemHeading}>特色</p>
                <p>{selectedInfo.feature ? selectedInfo.feature : '-'}</p>
              </div>
              <div css={ModalContentItem}>
                <p css={ModalContentItemHeading}>メモ</p>
                <div>
                  <ul>
                    {selectedInfo.memos.length > 0
                      ? selectedInfo.memos.map((item: any) => (
                          <li css={ModalContentItemList} key={item.id}>
                            {item.text}
                          </li>
                        ))
                      : '-'}
                  </ul>
                </div>
              </div>
              <Accordion
                title="営業用メモを残す"
                keyword="button"
                isOpen={isOpen}
                changeOpen={changeOpen}
              >
                <div css={ModalContentItemMemo}>
                  <div css={ModalContentItemMemoContent}>
                    <p css={ModalContentItemMemoContentHeading}>実績番号</p>
                    <input
                      css={ModalContentItemRecordInput}
                      type="text"
                      placeholder="例 ）1002004"
                      value={PostRecord}
                      onChange={e => handleChange(e, 'number')}
                    />
                  </div>
                  <div css={ModalContentItemMemoContent}>
                    <p css={ModalContentItemMemoContentHeading}>内容</p>
                    <textarea
                      css={ModalContentItemMemoInput}
                      placeholder="例 ）メモ"
                      value={PostContent}
                      onChange={e => handleChange(e, 'content')}
                    />
                  </div>
                  {isError === true ? (
                    <p css={ErrorMsg}>
                      入力に間違いがあります。もう一度入力してください
                    </p>
                  ) : null}
                  <button
                    css={ModalContentBtn}
                    onClick={() => PostMemo(selectedInfo)}
                  >
                    送信
                  </button>
                </div>
              </Accordion>
            </div>
          </div>
        </div>
      </React.Fragment>,
      document.body
    )
  )
}

// export default hot(Modal)

export default InfoModal

const ModalWrapper = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  overflow: scroll;
  overflow-wrap: break-word;
  background: none rgb(255, 255, 255);
  border: 0px;
  // user-select: none;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;

  @media (max-width: 768px) {
    width: 90%;
    height: 80%;
  }
`

const ModalContent = css`
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  max-width: 800px;
  margin: auto;
  @media (max-width: 768px) {
    padding: 40px 20px 40px 40px;
  }
  @media (max-width: 450px) {
    padding: 20px;
  }
`

const ModalContentTtl = css`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #0e2851;
`
const ModalContentItem = css`
  margin-bottom: 15px;
`

const ModalContentItemHeading = css`
  margin-bottom: 5px;
  font-weight: bold;
  background: linear-gradient(transparent 75%, #c1e0ff 75%);
  display: inline-block;
`

const ModalContentItemList = css`
  margin-left: 20px;
`

const ModalContentBtn = css`
  font-family: inherit;
  cursor: pointer;
  font-size: 16px;
  width: 220px;
  padding: 8px 30px;
  border-radius: 20px;
  border: none;
  background: #0e2851;
  color: #ffffff;
  outline: none;
  margin: auto;
  @media (max-width: 768px) {
    // font-size: 14px;
    width: 180px;
    margin-bottom: 10px;
  }
`

const ModalContentItemMemo = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0 10px;
`

const ModalContentItemMemoContent = css`
  width: 100%;
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
  &:nth-of-type(2) {
    margin-bottom: 30px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const ModalContentItemMemoContentHeading = css`
  width: 20%;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 5px;
  }
`

const ModalContentItemMemoInput = css`
  width: 80%;
  height: 100px;
  border: 1px solid #eee;
  background-color: #eee;
  padding: 10px;
  // font-size: 14px;
  font-size: 16px;
  border-radius: 3px;
  -webkit-appearance: none;
  @media (max-width: 768px) {
    width: 90%;
    margin-left: 5px;
  }
`
const ModalContentItemRecordInput = css`
  width: 80%;
  height: 30px;
  border: 1px solid #eee;
  background-color: #eee;
  padding: 10px;
  // font-size: 14px;
  font-size: 16px;
  border-radius: 3px;
  -webkit-appearance: none;
  @media (max-width: 768px) {
    width: 90%;
    margin-left: 5px;
  }
`

const CloseBtn = css`
  position: absolute;
  right: 0;
  border: 0px;
  margin: 20px;
  padding: 0px;
  cursor: pointer;
  // user-select: none;
  height: 30px;
  width: 30px;
  outline: none;
  background: none;
  top: 15%;
  left: 80%;
  z-index: 2;
  @media (max-width: 450px) {
    top: 10%;
  }
`

const ErrorMsg = css(`
    // font-size: 14px;
    font-size: 16px;
    font-weight: bold;
    color: #ff4d4d;
    margin-top: -25px;
    margin-bottom: 30px;

    @media (max-width: 450px) {
        margin-top: -25px;
        margin-bottom: 10px;
    }
`)
