import React, { memo } from 'react'
import { css } from '@emotion/core'

export const ModalInput = memo(({ onChange, val, text, type }: any) => {
  return (
    <div css={ModalContentSearchItem}>
      <p css={ModalContentItemSearchHeading}>{text}</p>
      <input
        css={ModalContentItemInput}
        type={type}
        placeholder={text}
        onChange={onChange}
        value={val}
      />
    </div>
  )
})

const ModalContentSearchItem = css`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: baseline;
    // margin-bottom: 15px;
  }
  @media (max-width: 450px) {
    margin-bottom: 10px;
  }
`

const ModalContentItemSearchHeading = css`
  margin-bottom: 5px;
  font-weight: bold;
  width: 15%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const ModalContentItemInput = css`
  font-family: sans-serif;
  width: 100%;
  margin-left: 20px;
  border: 1px solid #eee;
  background-color: #eee;
  padding: 10px;
  // font-size: 14px;
  font-size: 16px;
  border-radius: 3px;
  margin-right: 10px;
  -webkit-appearance: none;
  @media (max-width: 1440px) {
    max-width: none;
    margin-left: 20px;
  }
  @media (max-width: 768px) {
    margin-right: 0;
    margin-left: 0;
    width: calc(94% - 20px);
    // max-width: 400px;
    max-width: none;
  }
  @media (max-width: 450px) {
    width: 98%;
  }
`
