import React from 'react'
import { css } from '@emotion/core'

export const Accordion = ({
  title,
  children,
  keyword,
  isOpen,
  changeOpen
}: any) => {
  if (keyword === 'button') {
    return isOpen ? (
      <div css={accordionWrapper}>
        <div css={accordionBtnOpened} onClick={() => changeOpen()}>
          {title}
        </div>
        <div id="transitionTarget" css={accordionItemOpenedSecondary}>
          <div css={accordionContent}>{children}</div>
        </div>
      </div>
    ) : (
      <div css={accordionWrapper}>
        <div css={accordionBtn} onClick={() => changeOpen()}>
          {title}
        </div>
        <div css={accordionItemSecondary}>
          <div css={accordionContent}>{children}</div>
        </div>
      </div>
    )
  } else {
    return isOpen ? (
      <div css={accordionWrapper}>
        <div css={accordionTitleOpened} onClick={() => changeOpen()}>
          {title}
        </div>
        <div css={accordionItemOpened}>
          <div css={accordionContent}>{children}</div>
        </div>
      </div>
    ) : (
      <div css={accordionWrapper}>
        <div css={accordionTitle} onClick={() => changeOpen()}>
          {title}
        </div>
        <div css={accordionItem}>
          <div css={accordionContent}>{children}</div>
        </div>
      </div>
    )
  }
}

export const AccordionSmall = ({
  title,
  children,
  keyword,
  isOpen,
  changeOpen
}: any) => {
  return isOpen ? (
    <div
    // css={accordionWrapperSmall}
    >
      <div css={accordionSmallTitleOpened} onClick={() => changeOpen()}>
        {title}
      </div>
      <div css={accordionSmallContent}>
        <div id="transitionTarget" css={accordionItemOpenedSecondary}>
          <div css={accordionContent}>{children}</div>
        </div>
      </div>
    </div>
  ) : (
    <div
    // css={accordionWrapperSmall}
    >
      <div
        // css={accordionBtn}
        css={accordionSmallTitle}
        onClick={() => changeOpen()}
      >
        {title}
      </div>
      <div css={accordionSmallContent}>
        <div css={accordionItemSecondary}>
          <div css={accordionContent}>{children}</div>
        </div>
      </div>
    </div>
  )
}

const accordionWrapper = css(`
    fontFamily: inherit;
    // width: inherit;
    border: none;
    display: flex;
    flex-direction: column;
    float: left;


    width: 98%;
    margin-left: 0px;
    margin-right: 0px;

    
    @media (min-width: 500px) {
        width: 94%;
        margin-left: 0px;
        margin-right: 10px;
    }
    @media (min-width: 769px) {
        width: 98%;
        margin-left: 15px;
        margin-right: 10px;
    }
`)

// const accordionWrapperSmall = css(`
//     marginTop: 20px;
// `);

// 詳細表示用

const accordionBtn = css(`
    font-family: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    width: 220px;
    padding: 8px 30px;
    border-radius: 3px;
    border: 1px solid #0e2851;
    color: #0e2851;
    outline: none;
    margin: auto;
    @media (max-width: 768px) {
        // font-size: 14px;
        width: 180px;
        margin-bottom: 10px;
    }
    &:after{
        margin-left: 5px;
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid currentColor;
        color: #0e2851;
    }

`)
const accordionBtnOpened = css(`

    font-family: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    width: 220px;
    padding: 8px 30px;
    border-radius: 3px;
    border: 1px solid #0e2851;
    color: #0e2851;
    outline: none;
    margin: auto;
    @media (max-width: 768px) {
        // font-size: 14px;
        width: 180px;
        margin-bottom: 10px;
    }
    &:after{
        margin-left: 5px;
        content: "";
        border-top: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid;
        color: #0e2851;
        
    }

`)

const accordionItemSecondary = css(`
    overflow: hidden;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
    height: auto;
    max-height: 0px;
    background-color: #fff;
`)

const accordionItemOpenedSecondary = css(`
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1,0,1,0);
    height: auto;
    max-height: 9999px;
    background-color: #fff;
    border-radius: 3px;
    margin-bottom: 20px;
`)

// 検索用
const accordionTitle = css(`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #eee;
    background-color: #eee;
    padding: 10px;
    border-radius: 3px;
    // margin-right: 10px;
    float: right;
    color: #828282;
    font-size: 16px;
    &:after{
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid currentColor;
        color: #828282;
    }
    @media (max-width: 768px) {
        // max-width: none;
        // float: left;
        // width: 99%;
    }
    @media (min-width: 1170px) {
        // max-width: none;
        // width: 86%;
    }
`)

const accordionTitleOpened = css(`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #eee;
    background-color: #eee;
    padding: 10px;
    border-radius: 3px;
    // margin-right: 10px;
    float: right;
    color: #828282;
    // font-size: 14px;
    font-size: 16px;
    &:after{
        content: "";
        border-top: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid;
        color: #828282;
    }
    @media (max-width: 768px) {
        // max-width: none;
        // float: left;
        // width: 99%;
    }
`)

const accordionItem = css(
  `
    overflow: hidden;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
    height: auto;
    max-height: 0px;
    width: 99%;
    max-width: 422px;
    background-color: #fff;
    border-radius: 3px;
    margin-right: 10px;
    float: right;
    @media (max-width: 768px) {
        max-width: none;
        float: left;
        // width: 100%;
    }
    @media (min-width: 1170px) {
        max-width: none;
        width: 100%;
    }
`
)

const accordionItemOpened = css(`
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1,0,1,0);
    height: auto;
    max-height: 9999px;
    width: 99%;
    // max-width: 422px;
    background-color: #fff;
    border-radius: 3px;
    margin-right: 10px;
    float: right;
    margin-bottom: 20px;
    @media (max-width: 768px) {
        max-width: none;
        float: left;
        // width: 100%;
    }
    @media (min-width: 1170px) {
        max-width: none;
        width: 100%;
    }
`)

const accordionContent = css({
  //   display: 'flex'
})

// シナリオ用

const accordionSmallTitle = css({
  margin: '5px 0 5px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 0',
  width: '100%',
  '&:after': {
    content: "''",
    borderTop: '5px solid',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '0',
    color: '#828282',
    marginRight: '5px'
  },
  '@media(min-width: 768px)': {
    // maxWidth:"400px",
  }
})

const accordionSmallTitleOpened = css({
  margin: '5px 0 5px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 0',
  width: '100%',
  '&:after': {
    content: "''",
    borderTop: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '5px solid',
    color: '#828282',
    marginRight: '5px'
  }
})

const accordionSmallContent = css({
  borderBottom: '1px solid darkgray'
})
