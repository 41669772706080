import React from 'react'
import styled from '@emotion/styled'

export const InfoWindowItemSpecial = ({ info }: any) => {
  return (
    <InfoWindowLi>
      <InfoWindowLiImgWrap>
        <InfoWindowLiImg
          src={`${window.location.origin}/images/custom.png`}
          alt=""
        />
      </InfoWindowLiImgWrap>

      <IndoWindowLiBox>
        <InfoWindowLiTitle>{info.title}</InfoWindowLiTitle>
        <InfoWindowLiTxt>
          <InfoWindowLiTxtBold>住所：</InfoWindowLiTxtBold>
          {info.address ? info.address : '-'}
        </InfoWindowLiTxt>
        <InfoWindowLiTxtError>
          {info.gap && 'このピンの座標はズレている可能性があります。'}
        </InfoWindowLiTxtError>

        <InfoWindowLiTxt>
          <InfoWindowLiTxtBold>代表者電話番号：</InfoWindowLiTxtBold>
          {info.representative_phone_number ? (
            <a href={`tel:${info.representative_phone_number}`}>
              {info.representative_phone_number}
            </a>
          ) : (
            <p>-</p>
          )}
        </InfoWindowLiTxt>
        <InfoWindowLiTxt>
          <InfoWindowLiTxtBold>携帯電話番号：</InfoWindowLiTxtBold>
          {info.cellphone_number ? (
            <a href={`tel:${info.cellphone_number}`}>{info.cellphone_number}</a>
          ) : (
            <p>-</p>
          )}
        </InfoWindowLiTxt>
        <InfoWindowLiTxt>
          <InfoWindowLiTxtBold>申込店電話番号：</InfoWindowLiTxtBold>
          {info.store_phone_number ? (
            <a href={`tel:${info.store_phone_number}`}>
              {info.store_phone_number}
            </a>
          ) : (
            <p>-</p>
          )}
        </InfoWindowLiTxt>

        <InfoWindowLiTxt>
          <InfoWindowLiTxtBold>優先連絡先：</InfoWindowLiTxtBold>
          {info.top_priority_contact_detail
            ? info.top_priority_contact_detail
            : '-'}
        </InfoWindowLiTxt>

        <InfoWindowLiTxt>
          <InfoWindowLiTxtBold>受注番号：</InfoWindowLiTxtBold>
          {info.order_number ? info.order_number : '-'}
        </InfoWindowLiTxt>
      </IndoWindowLiBox>
    </InfoWindowLi>
  )
}

const InfoWindowLi = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  margin: 0 20px;
  border-bottom: solid 1px #cccccc;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // overflow: hidden;
  &:last-child {
    border-bottom: none;
  }
  @media (min-width: 450px) {
    margin: 0 30px;
  }
`

const InfoWindowLiImgWrap = styled.div`
  margin-right: 20px;
`
const InfoWindowLiImg = styled.img`
  height: auto;
  width: 20px;
`

const InfoWindowLiTitle = styled.p`
  font-size: 16px;
  padding-bottom: 5px;
  color: #4d4d4d;
  &:first-of-type {
    color: #333333;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
`
const InfoWindowLiTxt = styled.div`
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  padding-bottom: 5px;
  color: #4d4d4d;
  font-size: 14px;
  display: flex;
  align-items: baseline;

  &:first-of-type {
    color: #333333;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
`

const InfoWindowLiTxtBold = styled.span`
  font-weight: bold;
`
const InfoWindowLiTxtError = styled.p`
  color: #ef5350;
  padding-bottom: 5px;
  font-size: 14px;
  display: flex;
  align-items: baseline;
`

const IndoWindowLiBox = styled.div`
  // max-width: 170px;
  // width: 170px;
  @media (min-width: 450px) {
    width: 70%;
  }
`
