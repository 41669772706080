import React from 'react'
import { css } from '@emotion/core'

type $FIXME = any

export const Pin = ({ pin }: $FIXME) => {
  return (
    <img
      alt=""
      css={pinStyle}
      src={`${window.location.origin}/images/${
        pin.pin_id ? pin.pin_id : 'custom'
      }.png`}
    />
  )
}

// emotion
const pinStyle = css({
  width: '20px',
  height: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '-webkit-transform': 'translate(-50%, -50%)',
  '-ms-transform': 'translate(-50%, -50%)',
  cursor: 'pointer'
})
