import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory
} from 'react-router-dom'

import Login from './pages/Login/login'
import Map from './pages/Map/map'
import { Global, css } from '@emotion/core'
import axios from 'axios'
import { base_url } from '../utils/constants'

axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'

const App = () => {
  const LoginPage = () => {
    return (
      <div css={Main}>
        <div css={Header}>
          <h1 css={Title}>SmartSellMap</h1>
        </div>
        <Login />
      </div>
    )
  }

  const MapPage = () => {
    const history = useHistory()
    const logout = () => {
      const url = `${base_url}/api/logout`
      axios
        .get(url)
        .then(res => {
          history.goBack()
          // console.log(res)
        })
        .catch(err => {
          // console.log(err) // 失敗
        })
    }

    return (
      <div css={Main}>
        <div css={Header}>
          <h1 css={Title}>SmartSellMap</h1>
          <button css={LoginBtn} onClick={logout}>
            <img
              css={LoginBtnImg}
              alt=""
              src={`${window.location.origin}/images/logout.svg`}
            />
          </button>
        </div>
        <Map />
      </div>
    )
  }

  return (
    <Router>
      <Global styles={GlobalStyle} />
      <Switch>
        <Route path="/" exact>
          <LoginPage />
        </Route>
        <Route path="/map" exact>
          <MapPage />
        </Route>
      </Switch>
    </Router>
  )
}

export default App

const GlobalStyle = css`
  body {
    margin: 0;
    font-family: sans-serif;
    color: #333333;
  }
  h1 {
    margin: 0;
    font-family: sans-serif;
    font-size: 1.2rem;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
  }
  p {
    display: block;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  input {
    // -webkit-appearance: none;
  }
`

const displayHeight = window.innerHeight

const Main = css`
  height: ${displayHeight}px;
`
const Header = css`
  height: 6%;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0e2851;
  color: #fff;
`

const Title = css`
  font-size: 1.2rem;
  @media (max-width: 450px) {
    font-size: 1rem;
  }
`
const LoginBtn = css`
  background: none rgb(255, 255, 255);
  border: 0px;
  margin: 10px;
  padding: 0px;
  cursor: pointer;
  // user-select: none;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  height: 30px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 450px) {
    height: 25px;
    width: 40px;
  }
`

const LoginBtnImg = css`
  width: 20px;
  height: 20px;
  @media (max-width: 450px) {
    height: 17px;
    width: 17px;
  }
`
