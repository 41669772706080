import React from 'react'
import styled from '@emotion/styled'

export const InfoWindowItemNormal = ({ info, showModal }: any) => {
  return (
    <InfoWindowLi>
      <InfoWindowLiImg
        src={`${window.location.origin}/images/${info.pin_id}.png`}
        alt=""
      />
      <IndoWindowLiBox>
        <InfoWindowLiTitle>{info.title}</InfoWindowLiTitle>
        <InfoWindowLiTxt>
          <InfoWindowLiTxtBold>業態：</InfoWindowLiTxtBold>
          {info.category_id}
        </InfoWindowLiTxt>

        <InfoWindowLiTxt>
          <InfoWindowLiTxtBold>獲得済み商材：</InfoWindowLiTxtBold>
          {info.kakutoku ? info.kakutoku : '-'}
        </InfoWindowLiTxt>

        <InfoWindowLiTxt>
          <InfoWindowLiTxtBold>シナリオ：</InfoWindowLiTxtBold>
          {info.scenario ? info.scenario : '-'}
        </InfoWindowLiTxt>
        <InfoWindowLiTxt>
          <InfoWindowLiTxtBold>特色：</InfoWindowLiTxtBold>

          {info.feature ? info.feature : '-'}
        </InfoWindowLiTxt>
      </IndoWindowLiBox>
      <InfoWindowLiBtn onClick={() => showModal(info)}>詳細</InfoWindowLiBtn>
    </InfoWindowLi>
  )
}

const InfoWindowLi = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin: 0 20px;
  border-bottom: solid 1px #cccccc;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // overflow: hidden;
  &:last-child {
    border-bottom: none;
  }
  @media (min-width: 450px) {
    margin: 0 30px;
  }
`

const InfoWindowLiImg = styled.img`
  height: auto;
  width: 20px;
`

const InfoWindowLiTitle = styled.p`
  font-size: 16px;
  padding-bottom: 5px;
  color: #4d4d4d;
  &:first-of-type {
    color: #333333;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
`
const InfoWindowLiTxt = styled.p`
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  padding-bottom: 5px;
  color: #4d4d4d;
  font-size: 14px;
  &:first-of-type {
    color: #333333;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
`

const InfoWindowLiTxtBold = styled.span`
  font-weight: bold;
`

const IndoWindowLiBox = styled.div`
  // max-width: 170px;
  width: 170px;
  @media (min-width: 450px) {
    width: 70%;
  }
`

const InfoWindowLiBtn = styled.button`
  font-family: inherit;
  cursor: pointer;
  font-size: 14px;
  padding: 3px 15px;
  border-radius: 20px;
  border: none;
  background: #0e2851;
  color: #ffffff;
  outline: none;
`
