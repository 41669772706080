// ピン選択時のcenterを縦方向にずらす
export const fixCenterLat = (lat: number, zoom: any) => {
  switch (zoom) {
    case 3:
      return lat + 10
    case 4:
      return lat + 5
    case 5:
      return lat + 1
    case 6:
      return lat + 0.9
    case 7:
      return lat + 0.5
    case 8:
      return lat + 0.2
    case 9:
      return lat + 0.2
    case 10:
      return lat + 0.09
    case 11:
      return lat + 0.05
    case 12:
      return lat + 0.03
    case 13:
      return lat + 0.009
    case 14:
      return lat + 0.005
    case 15:
      return lat + 0.003
    case 16:
      return lat + 0.002
    case 17:
      return lat + 0.001
    case 18:
      return lat + 0.0005
    case 19:
      return lat + 0.0002
    case 20:
      return lat + 0.00008
    case 21:
      return lat + 0.00003
    default:
      return lat
  }
}
