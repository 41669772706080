import React from 'react'
import styled from '@emotion/styled'
import { InfoWindowItemNormal } from './InfoWindowItemNormal'
import { InfoWindowItemSpecial } from './InfoWindowItemSpecial'

export const InfoWindow = ({ detailInfo, showModal }: any) => {
  return (
    <InfoWindowUl>
      {detailInfo.map((info: any) => {
        // console.log(info)
        if (info.top_priority_contact_detail) {
          return <InfoWindowItemSpecial key={info.id} info={info} />
        } else {
          return (
            <InfoWindowItemNormal
              key={info.id}
              info={info}
              showModal={showModal}
            />
          )
        }
      })}
    </InfoWindowUl>
  )
}

const InfoWindowUl = styled.ul`
  width: 310px;
  height: 215px;
  overflow: scroll;
  padding: 10px 0 0;
  box-sizing: border-box;
  overflow-y: scroll;
  transform: translateZ(0);
  -ms-overflow-style: none;
  @media (min-width: 450px) {
    width: 550px;
    height: 350px;
    padding: 25px 0 0;
  }
`
