import React, { useState, useCallback } from 'react'
import { Accordion, AccordionSmall } from '../../components/modules/Accordion'
import { css } from '@emotion/core'
import axios from 'axios'
import {
  constCategories,
  constProducts,
  constScenarioBasic,
  constScenarioFinance,
  constScenarioIssues,
  constScenarioAnalysis,
  constScenarioService,
  constScenarioEmployment,
  constScenarioEmploymentArea,
  constScenarioEmploymentAnalysis
} from '../../../utils/constants'
import { ModalInput } from './ModalInput'
import { ModalCheckbox } from './ModalCheckbox'

// import console = require('console');

axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'

// 型宣言
type ScenarioArray = { id: number; title: string; type: string }
export const SearchModalNormal = ({
  hide,
  addFiltering,
  setThinning,
  setSearchFlag,
  filtering
}: any) => {
  // 顧客詳細情報表示用モーダル
  // 検索で仕様するプロパティ
  const [customerName, setCustomerName] = useState(filtering.name)
  const [customerAddress, setCustomerAddress] = useState(filtering.address)
  const [customerNumber, setCustomerNumber] = useState(filtering.number)
  const [checkedIndustries, setCheckedIndustries] = useState<{
    [key: string]: string
  }>(filtering.industries)
  const [checkedServices, setCheckedServices] = useState<{
    [key: string]: string
  }>(filtering.services)
  const [checkedScenario, setCheckedScenario] = useState<{
    [key: string]: string
  }>(filtering.scenario)
  // const [checkedFeature, setCheckedFeature] = useState<{[key: string]: string}>({});

  // Modal開閉管理
  const [isIndustoryOpen, setIndustoryOpen] = useState(false)
  const [isServiceOpen, setServiceOpen] = useState(false)
  const [isScenarioOpen, setScenarioOpen] = useState(false)
  const [isScenarioBasicOpen, setScenarioBasicOpen] = useState(false)
  const [isScenarioFinanceOpen, setScenarioFinanceOpen] = useState(false)
  const [isScenarioIssuesOpen, setScenarioIssuesOpen] = useState(false)
  const [isScenarioAnalysisOpen, setScenarioAnalysisOpen] = useState(false)
  const [isScenarioServiceOpen, setScenarioServiceOpen] = useState(false)
  const [isScenarioEmploymentOpen, setScenarioEmploymentOpen] = useState(false)
  const [
    isScenarioEmploymentAreaOpen,
    setScenarioEmploymentAreaOpen
  ] = useState(false)
  const [
    isScenarioEmploymentAnalysisOpen,
    setScenarioEmploymentAnalysisOpen
  ] = useState(false)
  // const [isFeatureChildOpen, setFeatureChildOpen] = useState(false);

  const changeOpenState = (key: string) => {
    switch (key) {
      case 'industory':
        setIndustoryOpen(!isIndustoryOpen)
        break
      case 'service':
        setServiceOpen(!isServiceOpen)
        break
      case 'scenario':
        setScenarioOpen(!isScenarioOpen)
        break
      // case "feature":
      //     setFeatureOpen(!isFeatureOpen)
      // break
      case 'scenarioBasic':
        setScenarioBasicOpen(!isScenarioBasicOpen)
        break
      case 'scenarioFinance':
        setScenarioFinanceOpen(!isScenarioFinanceOpen)
        break
      case 'scenarioIssues':
        setScenarioIssuesOpen(!isScenarioIssuesOpen)
        break
      case 'scenarioAnalysis':
        setScenarioAnalysisOpen(!isScenarioAnalysisOpen)
        break
      case 'scenarioService':
        setScenarioServiceOpen(!isScenarioServiceOpen)
        break
      case 'scenarioEmployment':
        setScenarioEmploymentOpen(!isScenarioEmploymentOpen)
        break
      case 'scenarioEmploymentArea':
        setScenarioEmploymentAreaOpen(!isScenarioEmploymentAreaOpen)
        break
      case 'scenarioEmploymentAnalysis':
        setScenarioEmploymentAnalysisOpen(!isScenarioEmploymentAnalysisOpen)
        break
      // case "featureChild":
      //     setFeatureChildOpen(!isFeatureChildOpen)
      // break
    }
  }

  const changeName = useCallback((e: any) => {
    setCustomerName(e.target.value)
  }, [])
  const changeAddress = useCallback((e: any) => {
    setCustomerAddress(e.target.value)
  }, [])

  const changeNumber = useCallback((e: any) => {
    setCustomerNumber(e.target.value)
  }, [])
  const changeIndustries = useCallback(
    (e: any) => {
      setCheckedIndustries({
        ...checkedIndustries,
        [e.target.id]: e.target.checked
      })
    },
    [checkedIndustries]
  )
  const changeServices = useCallback(
    (e: any) => {
      setCheckedServices({
        ...checkedServices,
        [e.target.id]: e.target.checked
      })
    },
    [checkedServices]
  )
  const changeScenario = useCallback(
    (e: any) => {
      setCheckedScenario({
        ...checkedScenario,
        [e.target.id]: e.target.checked
      })
    },
    [checkedScenario]
  )

  const filter = {
    name: customerName,
    address: customerAddress,
    number: customerNumber,
    industries: checkedIndustries,
    services: checkedServices,
    scenario: checkedScenario
  }

  const clearSearchInput = () => {
    setCustomerName('')
    setCustomerAddress('')
    setCustomerNumber('')
    setCheckedIndustries({})
    setCheckedServices({})
    setCheckedScenario({})
  }

  // フィルタリングを親要素に伝達
  const [changeThinning, setChangeThinning] = useState(false)
  const newArray = (hash: any) =>
    Object.keys(hash).map((key: any) => {
      if (hash[key] === true) {
        return key
      } else {
        return null
      }
    })

  const sendSearchInput = () => {
    const senarioArray = newArray(checkedScenario)
      .filter(Boolean)
      .map(Number)
    //  シナリオ有無で全表示/間引きをかえる
    if (senarioArray.length > 0) {
      //   console.log("選択して検索！")
      setThinning('')
      setChangeThinning(true)
    } else if (senarioArray.length <= 0 && changeThinning === true) {
      //   console.log('選択を解除して検索！')
      setThinning('2')
      setChangeThinning(false)
    }
    // console.log(addFiltering)
    setSearchFlag(1)
    addFiltering(filter)
    hide()
  }

  return (
    <>
      <div>
        <ModalInput
          onChange={changeName}
          val={customerName}
          text={'顧客名'}
          type={'text'}
        />
        <ModalInput
          onChange={changeAddress}
          val={customerAddress}
          text={'住所'}
          type={'text'}
        />
        <ModalInput
          onChange={changeNumber}
          val={customerNumber}
          text={'電話番号'}
          type={'tel'}
        />

        <div css={ModalContentSearchItem}>
          <p css={ModalContentItemSearchHeading}>業態</p>
          <Accordion
            title="選択してください"
            keyword=""
            isOpen={isIndustoryOpen}
            changeOpen={() => changeOpenState('industory')}
          >
            {constCategories.map(item => (
              <ModalCheckbox
                item={item}
                array={checkedIndustries}
                onChange={changeIndustries}
                key={item.id}
              />
            ))}
          </Accordion>
        </div>
        <div css={ModalContentSearchItem}>
          <p css={ModalContentItemSearchHeading}>獲得済み商材</p>
          <Accordion
            title="選択してください"
            keyword=""
            isOpen={isServiceOpen}
            changeOpen={() => changeOpenState('service')}
          >
            {constProducts.map(item => (
              <ModalCheckbox
                item={item}
                array={checkedServices}
                onChange={changeServices}
                key={item.id}
              />
            ))}
          </Accordion>
        </div>
        <div css={ModalContentSearchItem}>
          <p css={ModalContentItemSearchHeading}>シナリオ</p>
          <Accordion
            title="選択してください"
            keyword=""
            isOpen={isScenarioOpen}
            changeOpen={() => changeOpenState('scenario')}
          >
            <AccordionSmall
              title="【 基本属性 】"
              keyword=""
              isOpen={isScenarioBasicOpen}
              changeOpen={() => changeOpenState('scenarioBasic')}
            >
              {constScenarioBasic.map((item: ScenarioArray) => (
                <ModalCheckbox
                  item={item}
                  array={checkedScenario}
                  onChange={changeScenario}
                  key={item.id}
                />
              ))}
            </AccordionSmall>
            <AccordionSmall
              title="【 財務分析 】"
              keyword=""
              isOpen={isScenarioFinanceOpen}
              changeOpen={() => changeOpenState('scenarioFinance')}
            >
              {constScenarioFinance.map((item: ScenarioArray) => (
                <ModalCheckbox
                  item={item}
                  array={checkedScenario}
                  onChange={changeScenario}
                  key={item.id}
                />
              ))}
            </AccordionSmall>
            <AccordionSmall
              title="【 有価証券報告書の経営課題 】"
              keyword=""
              isOpen={isScenarioIssuesOpen}
              changeOpen={() => changeOpenState('scenarioIssues')}
            >
              {constScenarioIssues.map((item: ScenarioArray) => (
                <ModalCheckbox
                  item={item}
                  array={checkedScenario}
                  onChange={changeScenario}
                  key={item.id}
                />
              ))}
            </AccordionSmall>
            <AccordionSmall
              title="【 利用サービス分析 】"
              keyword=""
              isOpen={isScenarioAnalysisOpen}
              changeOpen={() => changeOpenState('scenarioAnalysis')}
            >
              {constScenarioAnalysis.map((item: ScenarioArray) => (
                <ModalCheckbox
                  item={item}
                  array={checkedScenario}
                  onChange={changeScenario}
                  key={item.id}
                />
              ))}
            </AccordionSmall>
            <AccordionSmall
              title="【 提供サービス 】"
              keyword=""
              isOpen={isScenarioServiceOpen}
              changeOpen={() => changeOpenState('scenarioService')}
            >
              {constScenarioService.map((item: ScenarioArray) => (
                <ModalCheckbox
                  item={item}
                  array={checkedScenario}
                  onChange={changeScenario}
                  key={item.id}
                />
              ))}
            </AccordionSmall>
            <AccordionSmall
              title="【 求人情報（指定した職種での募集がある企業） 】"
              keyword=""
              isOpen={isScenarioEmploymentOpen}
              changeOpen={() => changeOpenState('scenarioEmployment')}
            >
              {constScenarioEmployment.map((item: ScenarioArray) => (
                <ModalCheckbox
                  item={item}
                  array={checkedScenario}
                  onChange={changeScenario}
                  key={item.id}
                />
              ))}
            </AccordionSmall>
            <AccordionSmall
              title="【 求人情報（指定した地域での募集がある企業） 】"
              keyword=""
              isOpen={isScenarioEmploymentAreaOpen}
              changeOpen={() => changeOpenState('scenarioEmploymentArea')}
            >
              {constScenarioEmploymentArea.map((item: ScenarioArray) => (
                <ModalCheckbox
                  item={item}
                  array={checkedScenario}
                  onChange={changeScenario}
                  key={item.id}
                />
              ))}
            </AccordionSmall>
            <AccordionSmall
              title="【 求人情報分析 】"
              keyword=""
              isOpen={isScenarioEmploymentAnalysisOpen}
              changeOpen={() => changeOpenState('scenarioEmploymentAnalysis')}
            >
              {constScenarioEmploymentAnalysis.map((item: ScenarioArray) => (
                <ModalCheckbox
                  item={item}
                  array={checkedScenario}
                  onChange={changeScenario}
                  key={item.id}
                />
              ))}
            </AccordionSmall>
          </Accordion>
        </div>
      </div>
      <div css={ModalContentBtnArea}>
        <button
          css={ModalContentClearBtn}
          type="button"
          className="modal-close-button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => clearSearchInput()}
        >
          <span aria-hidden="true">Clear</span>
        </button>
        <button css={ModalContentBtn} onClick={() => sendSearchInput()}>
          Search
        </button>
      </div>
    </>
  )
}

const ModalContentSearchItem = css`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: baseline;
    // margin-bottom: 15px;
  }
  @media (max-width: 450px) {
    margin-bottom: 10px;
  }
`

const ModalContentItemSearchHeading = css`
  margin-bottom: 5px;
  font-weight: bold;
  width: 15%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const ModalContentBtnArea = css`
  width: 80%;
  max-width: 600px;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column-reverse;
    margin-top: 40px;
  }
`
const ModalContentBtn = css`
  font-family: inherit;
  cursor: pointer;
  font-size: 16px;
  width: 220px;
  padding: 8px 30px;
  border-radius: 20px;
  border: none;
  background: #0e2851;
  color: #ffffff;
  outline: none;
  margin: auto;
  @media (max-width: 768px) {
    // font-size: 14px;
    width: 180px;
    margin-bottom: 10px;
  }
`

const ModalContentClearBtn = css`
  font-family: inherit;
  cursor: pointer;
  font-size: 16px;
  width: 220px;
  padding: 8px 30px;
  border-radius: 20px;
  border: none;
  background: #cccccc;
  color: #ffffff;
  outline: none;
  margin: auto;
  @media (max-width: 768px) {
    // font-size: 14px;
    width: 180px;
  }
`
